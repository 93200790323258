import React from 'react'

export const PrivacyPolicy = () => {
    return (
        <div>
            <div className="container-fluid py-lg-5 py-3 my-5">
                <div className="container">
                    <div>
                        <div className='text-lg-start text-center'>
                            <h1>Privacy Policy:</h1>
                        </div>
                        <div className='pt-3'>
                            <p className='text-lg-start text-center'><span className='fw-bold'>Fixtech Solution</span> is committed to protecting the privacy and security of your personal information. This
                                Privacy Policy describes how we collect, use, and disclose information when you use our website and the
                                services we offer, including website design and development, WordPress development, Shopify store
                                setup, hosting and domain services, and graphic design services
                            </p>

                            <div className='pt-4'>
                                <div className='text-lg-start text-center'>
                                    <h5>Information We Collect</h5>
                                    <p>When you visit our website or use our services, we may collect the following types of information:
                                    </p>
                                </div>

                                <ol className='pt-3 pt-lg-2'>
                                    <li>
                                        <h6>Personal Information:</h6>
                                        <p>This may include your name, email address, phone number, postal
                                            address, and other contact information provided voluntarily when you fill out forms or
                                            communicate with us.
                                        </p>
                                    </li>
                                    <li>
                                        <h6>Payment Information:</h6>
                                        <p>If you make a purchase or engage in a transaction with us, we may
                                            collect payment information such as credit card details or other financial information necessary
                                            to process your payment.
                                        </p>
                                    </li>
                                    <li>
                                        <h6>Usage Information:</h6>
                                        <p>We may collect information about how you interact with our website and
                                            services, such as your IP address, browser type, pages visited, and the duration of your visit.</p>
                                    </li>
                                    <li>
                                        <h6>Cookies and Tracking Technologies:</h6>
                                        <p>We use cookies and similar tracking technologies to
                                            enhance your experience, analyze trends, administer the website, and track users' movements
                                            around the site.</p>
                                    </li>
                                </ol>
                            </div>

                            <div className='pt-4'>
                                <div className='text-lg-start text-center'>
                                    <h5>How We Use Your Information</h5>
                                    <p>We may use the information we collect for the following purposes:</p>
                                </div>

                                <ol className='pt-2'>
                                    <li>
                                        <p>To provide and maintain our services, including fulfilling orders, processing payments, and communicating with you about your account.
                                        </p>
                                    </li>
                                    <li>
                                        <p>To improve our website and services, including analyzing trends and user preferences, troubleshooting technical issues, and developing new features.
                                        </p>
                                    </li>
                                    <li>
                                        <p>To personalize your experience and deliver relevant content and advertisements based on your interests and preferences.</p>
                                    </li>
                                    <li>
                                        <p>To communicate with you, respond to your inquiries, and provide customer support.</p>
                                    </li>
                                    <li>
                                        <p>To comply with legal obligations and enforce our policies.</p>
                                    </li>
                                </ol>
                            </div>

                            <div className='pt-4'>
                                <div className='text-lg-start text-center'>
                                    <h5>Information Sharing and Disclosure</h5>
                                    <p>We may share your information with third parties in the following circumstances:</p>
                                </div>

                                <ol className='pt-3 pt-lg-2'>
                                    <li>
                                        <h6> Service Providers:</h6>
                                        <p>We may engage third-party service providers to assist us in providing our
                                            services, such as payment processors, hosting providers, and analytics services. These service
                                            providers may have access to your personal information only to perform these tasks on our
                                            behalf and are obligated not to disclose or use it for any other purpose.
                                        </p>
                                    </li>
                                    <li>
                                        <h6>Legal Compliance:</h6>
                                        <p>We may disclose your information if required to do so by law or in response
                                            to valid requests from public authorities (e.g., court orders or subpoenas).
                                        </p>
                                    </li>
                                    <li>
                                        <h6>Business Transfers:</h6>
                                        <p>If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction. We will notify you via
                                            email and/or a prominent notice on our website of any change in ownership or uses of your
                                            personal information, as well as any choices you may have regarding your information.
                                        </p>
                                    </li>
                                </ol>
                            </div>

                            <div className='pt-4'>
                                <div className='text-lg-start text-center'>
                                    <h5>Your Choices and Rights</h5>
                                    <p>You may have the following rights regarding your personal information:</p>
                                </div>

                                <ol className='pt-3 pt-lg-2'>
                                    <li>
                                        <h6>Access and Correction:</h6>
                                        <p>You have the right to access and update your personal information held
                                            by us. You may also request corrections to any inaccuracies in your information.
                                        </p>
                                    </li>
                                    <li>
                                        <h6>Opt-Out:</h6>
                                        <p>You may opt-out of receiving promotional communications from us by following the
                                            unsubscribe instructions provided in the communication or by contacting us directly.
                                        </p>
                                    </li>
                                    <li>
                                        <h6>Do Not Track:</h6>
                                        <p>: Our website does not respond to "Do Not Track" signals from your browser.
                                            However, you can set your browser to refuse all or some browser cookies or to alert you when
                                            websites set or access cookies.
                                        </p>
                                    </li>
                                </ol>
                            </div>

                            <div className='text-lg-start text-center pt-4'>
                                <h5>Data Security</h5>
                                <p>We take reasonable measures to protect your personal information from unauthorized access, use, or
                                    disclosure. However, no method of transmission over the internet or electronic storage is 100% secure,
                                    and we cannot guarantee absolute security.</p>
                            </div>
                            <div className='text-lg-start text-center pt-4'>
                                <h5>Changes to This Privacy Policy</h5>
                                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other
                                    operational, legal, or regulatory reasons. We will notify you of any material changes by posting the
                                    updated Privacy Policy on this page with a new effective date.</p>
                            </div>

                            <div className='text-lg-start text-center pt-4'>
                                <h4>Contact Us</h4>
                                <p>If you have any questions or concerns about this Privacy Policy or our practices regarding your personal
                                    information, please contact us at
                                    <span className='fw-bold'> info@fixtechsolution.net</span></p>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}
