import React from "react";

const Header = () => {
  return (
    <>
      <div className="container-fluid bg-dark py-2 d-none d-md-flex">
            <div className="container">
                <div className="d-flex justify-content-between topbar">
                    <div className="top-info">
                        <small className=""><a href="tel:+923128789629" className="tel_num"><i className="fas fa-phone-alt me-2 blue_color"></i>+(92) 312-8789629</a></small>
                        <small className=""><a href="mailto:info@fixtechsolution.net" className="tel_num"><i className="fas fa-envelope me-2 blue_color"></i>info@fixtechsolution.net</a></small>
                    </div>
                    <div id="note" className="text-warning d-none d-xl-flex align-items-center"><small>Note : We help you to Grow your Business</small></div>
                    <div className="top-link">
                        <a href="https://www.facebook.com/profile.php?id=61558796034738" className=" nav-fill btn btn-sm-square rounded-circle circle_hover"><i className="fab fa-facebook-f"></i></a>
                        {/* <!-- <a href="" className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-twitter text-primary"></i></a> --> */}
                        <a href="" className=" nav-fill btn btn-sm-square rounded-circle circle_hover"><i className="fab fa-instagram"></i></a>
                        <a href="mailto:info@fixtechsolution.net" className=" nav-fill btn btn-sm-square rounded-circle circle_hover"><i className="fa fa-envelope"></i></a>
                        <a href="https://www.linkedin.com/in/fixtech-solution-948809304/" className=" nav-fill btn btn-sm-square rounded-circle me-0 circle_hover"><i className="fab fa-linkedin-in"></i></a>
                        
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Header;
