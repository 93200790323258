import React from 'react'
import { Link } from 'react-router-dom'

export const About = () => {
    return (
        <div>
            <div className="container-fluid py-5 my-5">
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/image-10.jpg" className="img-fluid w-75 rounded" alt="" style={{ marginBottom: "25%", marginLeft: "12%" }} />
                                {/* <!-- <div className="position-absolute w-75" style="top: 25%; left: 25%;">
                                <img src="img/image-9.jpg" className="img-fluid w-100 rounded" alt="">
                            </div> --> */}
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">About Us</h5>
                            <h1 className="mb-4">About FixTech Agency And It's Innovative IT Solutions</h1>
                            <p>At FixTech Solutions, we are committed to revolutionizing the digital landscape through cutting-edge IT services, innovative web and app development solutions, and strategic digital marketing strategies.</p>
                            <p className="mb-4">At FixTech Solutions, excellence is not just a goal, it's our standard. We are dedicated to delivering solutions that not only meet but exceed industry standards, ensuring reliability, scalability, and performance.</p>
                            <div className='mt-5'>
                                <Link to="/aboutaddition" className="change_btn_2 mehroz">More Details</Link>
                                <Link to="/services" className="change_btn_2 fact_color mehroz">See our Services</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
