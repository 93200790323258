import React from 'react'
import { Link } from 'react-router-dom'

export const WebDev = () => {
  return (
    <div>
        <div className="container-fluid py-5 my-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/image-11.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "20%", marginLeft: "12%"}}/>
                                {/* <!-- <div className="position-absolute w-75" style="top: 25%; left: 25%;">
                                    <img src="img/image-9.jpg" className="img-fluid w-100 rounded" alt="">
                                </div> --> */}
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">Web Development</h5>
                            <h1 className="mb-4">Elevate Your Online Presence with Fixtech's Expert Web Development</h1>
                            <p>At Fixtech, we specialize in creating bespoke online experiences that drive results. Our team of seasoned developers harnesses the latest technologies and industry best practices to build custom websites that are as functional as they are beautiful. Whether you're a startup, small business, or enterprise, we have the expertise to bring your vision to life, from concept to launch and beyond. Trust Fixtech to elevate your online presence and help you stand out in the digital landscape – contact us today to get started.</p>
                            <p className="mb-4">At FixTech Solutions, excellence is not just a goal, it's our standard. We are dedicated to delivering solutions that not only meet but exceed industry standards, ensuring reliability, scalability, and performance.</p>
                            <Link to="/contact" className="change_btn_2 mehroz">Book an order</Link>
                            <Link to="/services" className="change_btn_2 fact_color mehroz">See more Services</Link>

                        </div>
                        
                    </div>
                </div>
            </div>
    </div>
  )
}
