import React from 'react'

export const TermsAndCondition = () => {
    return (
        <div>
            <div className="container-fluid py-lg-5 py-3 my-5">
                <div className="container">
                    <div>
                        <div className='text-lg-start text-center'>
                            <h1>Terms & Conditions:</h1>
                        </div>
                        <div className='pt-3'>
                            <p className='text-lg-start text-center'>These Terms and Conditions ("Terms") govern your use of the services provided by Fixtech Solution and
                                your access to our website. By accessing or using our website and services, you agree to be bound by
                                these Terms. If you do not agree to these Terms, please refrain from using our website and services.
                            </p>
                            <ol className='pt-3'>
                                <li>
                                    <h6>Service Description</h6>
                                    <p>Fixtech Solution provides website design and development, WordPress development, Shopify store
                                        setup, hosting and domain services, and graphic design services ("Services"). The specific details and
                                        terms for each service are outlined on our website or as agreed upon in writing between you and
                                        Fixtech Solution.</p>
                                </li>
                                <li>
                                    <h6>Payment and Fees</h6>
                                    <p>Payment for our services is due as agreed upon in our contract or as specified on our website. We
                                        reserve the right to modify our prices and fees at any time, provided that such changes will not apply
                                        retroactively to services already performed. All payments are non-refundable unless otherwise stated in
                                        writing.</p>
                                </li>
                                <li>
                                    <h6>Intellectual Property</h6>
                                    <p>Unless otherwise agreed in writing, all intellectual property rights, including copyrights, trademarks, and
                                        trade secrets, related to the Services provided by Fixtech Solution shall remain the property of Fixtech
                                        Solution. You agree not to reproduce, distribute, modify, or create derivative works based on our
                                        Services without our prior written consent.
                                    </p>
                                </li>
                                <li>
                                    <h6>Confidentiality</h6>
                                    <p>Both parties agree to keep confidential any proprietary or sensitive information disclosed during the
                                        course of our engagement. This includes but is not limited to business strategies, trade secrets, and
                                        technical information. Confidential information shall not be disclosed to any third party without the
                                        other party's prior written consent.</p>
                                </li>
                                <li>
                                    <h6>Limitation of Liability</h6>
                                    <p>To the fullest extent permitted by law, Fixtech Solution shall not be liable for any indirect, incidental,
                                        special, consequential, or punitive damages arising out of or in connection with our Services, even if we
                                        have been advised of the possibility of such damages. Our total liability for any claim arising out of or
                                        relating to these Terms or our Services shall not exceed the total amount paid by you for the relevant
                                        service.</p>
                                </li>
                                <li>
                                    <h6>Indemnification</h6>
                                    <p>You agree to indemnify, defend, and hold harmless Fixtech Solution and its officers, directors,
                                        employees, and agents from and against any claims, liabilities, damages, losses, and expenses (including
                                        legal fees) arising out of or in connection with your use of our Services or any violation of these Terms.</p>
                                </li>
                                <li>
                                    <h6>Termination</h6>
                                    <p>We reserve the right to suspend or terminate your access to our Services at any time for any reason,
                                        including but not limited to breach of these Terms or non-payment of fees. Upon termination, you must
                                        cease all use of our Services and return any materials or information provided by Fixtech Solution.</p>
                                </li>
                                <li>
                                    <h6>Entire Agreement</h6>
                                    <p>These Terms constitute the entire agreement between you and Fixtech Solution regarding your use of
                                        our Services, superseding any prior agreements or understandings, whether written or oral.</p>
                                </li>
                                <li>
                                    <h6>Changes to Terms</h6>
                                    <p>We reserve the right to update or modify these Terms at any time without prior notice. Any changes will
                                        be effective immediately upon posting on our website. Your continued use of our Services after any such
                                        changes constitutes your acceptance of the revised Terms.</p>
                                </li>
                            </ol>

                            <div className='text-lg-start text-center pt-4'>
                                <h4>Contact Us</h4>
                                <p>If you have any questions or concerns about these Terms and Conditions, please contact us at
                                <span className='fw-bold'> info@fixtechsolution.net</span></p>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}
