import Header from './Components/Header';
import { Navbar } from './Components/Navbar';
import { Carousel } from './Components/Carousel';
import { About } from './Components/About';
import { AboutAddition } from './Components/AboutAddition';
import { Services } from './Components/Services';
import { Projects } from './Components/Projects';
// import  Team  from './Components/Team';
import { Contact } from './Components/Contact';
import { Footer } from './Components/Footer';
import { BacktoTop } from './Components/BacktoTop';
import { ACarousel } from './Components/AboutPage/ACarousel';
import {createBrowserRouter , RouterProvider} from 'react-router-dom'
import { WebDes } from './Components/Services/WebDes';
import { ScrollRestoration } from 'react-router-dom';
import { WebDev } from './Components/Services/WebDev';
import { GraphicDes } from './Components/Services/GraphicDes';
import { Shopify } from './Components/Services/Shopify';
import { HostDom } from './Components/Services/HostDom';
import {WordPress} from './Components/Services/WordPress';
import { TermsAndCondition } from './Components/TermsAndCondition';
import { PrivacyPolicy } from './Components/PrivacyPolicy';

function App() {
  const router = createBrowserRouter([
    {
      path:"/",
      element:<>
      <ScrollRestoration/>
      <Navbar></Navbar>
      <Carousel/>
      <About/>
      <Services/>
      {/* <Team/> */}
      <Projects/>
      <Contact/>
      <Footer></Footer>
      </>
    },
    {
      path:"/about",
      element:
      <>
      <ScrollRestoration/>
      <Navbar></Navbar>
      <ACarousel name={"About us"}/>
      <About/>
      {/* <Team/> */}
      <Footer></Footer>
      </>
    },
    {
      path:"/aboutaddition",
      element:
      <>
      <ScrollRestoration/>
      <Navbar></Navbar>
      <ACarousel name={"About us"}/>
      <AboutAddition/>
      <Footer></Footer>
      </>
    },
    {
      path:'/services',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <Services/>
        <Footer></Footer>
      </>
    },
    {
      path:'/project',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Projects"}/>
        <Projects></Projects>
        <Footer></Footer>
      </>
    },
    {
      path:'/contact',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Contact"}/>
        <Contact/>
        <Footer></Footer>
      </>
    },
    {
      path:'/WebDes',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <WebDes/>
        <Footer></Footer>
      </>
    },
    {
      path:'/WebDev',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <WebDev/>
        <Footer></Footer>
      </>
    },
    {
      path:'/WordPress',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <WordPress/>
        <Footer></Footer>
      </>
    },
    {
      path:'/Shopify',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <Shopify/>
        <Footer></Footer>
      </>
    },
    {
      path:'/HostDom',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <HostDom/>
        <Footer></Footer>
      </>
    },
    {
      path:'/GraphicDes',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Services"}/>
        <GraphicDes/>
        <Footer></Footer>
      </>
    },
    {
      path:'/TermsAndCondition',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Terms And Conditions"}/>
        <TermsAndCondition/>
        <Footer></Footer>
      </>
    },
    {
      path:'/PrivacyPolicy',
      element:
      <>
        <ScrollRestoration/>
        <Navbar></Navbar>
        <ACarousel name={"Privacy Policy"}/>
        <PrivacyPolicy/>
        <Footer></Footer>
      </>
    },
  ])
  return (
    <>

      <Header></Header>
      <RouterProvider router={router}/>
      <BacktoTop></BacktoTop>
    </>
  );
}

export default App;
