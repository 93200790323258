import React from 'react'
import { Link } from 'react-router-dom'

export const Navbar = () => {
  return (
    <div>
        <div className="container-fluid bg-color">
            <div className="container">
                <nav className="navbar navbar-dark navbar-expand-lg py-0">
                    <Link to="/" className="navbar-brand">
                        <h1 className="text-white fw-bold d-block mt-2">Fix<span className="change_color">Tech</span> </h1>
                    </Link>
                    <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
                        <div className="navbar-nav ms-auto p-0 gap-3">
                            <Link to="/" className="nav-item nav-link">Home</Link>
                            <Link to="/aboutaddition" className="nav-item nav-link">About</Link>
                            <Link to="/services" className="nav-item nav-link">Services</Link>
                            <Link to="/project" className="nav-item nav-link">Projects</Link>
                            {/* <!-- <div className="nav-item dropdown">
                                <a to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                <div className="dropdown-menu rounded">
                                    <a to="blog.html" className="dropdown-item">Our Blog</a>
                                    <a to="team.html" className="dropdown-item">Our Team</a>
                                    <a to="testimonial.html" className="dropdown-item">Testimonial</a>
                                    <a to="404.html" className="dropdown-item">404 Page</a>
                                </div>
                            </div> --> */}
                            <Link to="/contact" className="nav-item nav-link">Contact</Link>
                        </div>
                    </div>
                    {/* <!-- <div className="d-none d-xl-flex flex-shirink-0">
                        <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                            <a to="" className="position-relative animated tada infinite">
                                <i className="fa fa-phone-alt text-white fa-2x"></i>
                                <div className="position-absolute" style="top: -7px; left: 20px;">
                                    <span><i className="fa fa-comment-dots text-secondary"></i></span>
                                </div>
                            </a>
                        </div>
                        <div className="d-flex flex-column pe-4 border-end">
                            <span className="text-white-50">Have any questions?</span>
                            <span className="text-secondary">Call: + 0123 456 7890</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-center ms-4 ">
                            <a to="#"><i className="bi bi-search text-white fa-2x"></i> </a>
                        </div>
                    </div> --> */}
                </nav>
            </div>
        </div>
    </div>
  )
}
