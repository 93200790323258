import React from 'react'
import { Link } from 'react-router-dom'

export const Shopify = () => {
  return (
    <div>
        <div className="container-fluid py-5 my-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/roberto-cortese-G7KFCZzF_Hs-unsplash.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "25%", marginLeft: "12%"}}/>
                                
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">Shopify</h5>
                            <h1 className="mb-4">Optimize Your E-Commerce Store with Our Shopify Services</h1>
                            <p>FixTech is a dynamic software house specializing in Shopify development, dedicated to providing innovative solutions for e-commerce businesses. With a talented team of developers and designers, FixTech delivers tailored Shopify applications, themes, and integrations to enhance online stores functionality and aesthetics. Our commitment to excellence ensures seamless user experiences and optimized performance, empowering merchants to thrive in the competitive e-commerce landscape.</p>
                            <p className="mb-4">At FixTech Solutions, excellence is not just a goal, it's our standard. We are dedicated to delivering solutions that not only meet but exceed industry standards, ensuring reliability, scalability, and performance.</p>
                            <Link to="/contact" className="change_btn_2 mehroz">Book an order</Link>
                            <Link to="/services" className="change_btn_2 fact_color mehroz">See more Services</Link>

                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
