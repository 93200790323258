import React from 'react'
import { Link } from 'react-router-dom'

export const HostDom = () => {
  return (
    <div>
        <div className="container-fluid py-5 my-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/christina-wocintechchat-com-5UHFPbvBBzY-unsplash.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "25%", marginLeft: "12%"}}/>
                                
                            </div>
                        </div>
                       
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">Hosting and Domain</h5>
                            <h1 className="mb-4">Our Digital Foundation with Fixtech's Hosting and Domain Services</h1>
                            <p>Fixtech ensures your website's performance and reliability with tailored hosting and domain services. Expect high-speed hosting with lightning-fast loading times, top-notch security through advanced measures, and 24/7 support for any issues. We offer easy domain management and scalable solutions to grow with your business.</p>
                            <p className="mb-4">At FixTech Solutions, excellence is not just a goal, it's our standard. We are dedicated to delivering solutions that not only meet but exceed industry standards, ensuring reliability, scalability, and performance.</p>
                            <Link to="/contact" className="change_btn_2 mehroz">Book an order</Link>
                            <Link to="/services" className="change_btn_2 fact_color mehroz">See more Services</Link>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
