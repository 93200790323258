import React from 'react'
import { Link } from 'react-router-dom'

export const WebDes = () => {
  return (
    <div>
        <div className="container-fluid py-5 my-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/image-8.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "25%"}}/>
                                <div className="position-absolute w-75" style={{top: "25%", left: "25%"}}>
                                    <img src="img/image-4.jpg" className="img-fluid w-100 rounded" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">Web Design</h5>
                            <h1 className="mb-4">Forge a Digital Masterpiece with Fixtech's Web Design Expertise</h1>
                            <p> Unlock the full potential of your online presence with Fixtech's top-tier web design services. Our team of skilled designers combines creativity with functionality to craft visually stunning and user-friendly websites that leave a lasting impact. From sleek layouts to intuitive navigation, we ensure that your website not only looks great but also delivers an exceptional user experience across all devices. Trust Fixtech to transform your digital vision into reality – contact us today to elevate your web presence.</p>
                            <p className="mb-4">At FixTech Solutions, excellence is not just a goal, it's our standard. We are dedicated to delivering solutions that not only meet but exceed industry standards, ensuring reliability, scalability, and performance.</p>
                            <div className=''>
                                <Link to="/contact" className="change_btn_2 mehroz">Book an order</Link>
                                <Link to="/services" className="change_btn_2 fact_color mehroz">See more Services</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
