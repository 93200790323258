import React from 'react'
import { Link } from 'react-router-dom'

export const Services = () => {
  return (
    <div>
        <div className="container-fluid services py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="blue_color">Our Services</h5>
                    <h1>Services Built Specifically For Your Business</h1>
                </div>
                <div className="row g-5 services-inner">
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-code fa-7x mb-4"></i>
                                    <h4 className="mb-3">Web Design</h4>
                                    <p className="mb-5">Get a stunning website that stands out and delights your visitors with our expert web design services.</p>
                                    <Link to="/WebDes" className="change_btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-file-code fa-7x mb-4"></i>
                                    <h4 className="mb-3">Web Development</h4>
                                    <p className="mb-5">Let us bring your ideas to life online with our top-notch web development services. From building custom websites to creating powerful web applications.</p>
                                    <Link to="/WebDev" className="change_btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-external-link-alt fa-7x mb-4"></i>
                                    <h4 className="mb-3">Wordpress Development</h4>
                                    <p className="mb-5">Transform your ideas into stunning WordPress websites with our expert development services.</p>
                                    <Link to="/WordPress" className="change_btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fa fa-envelope-open fa-7x mb-4"></i>
                                    <h4 className="mb-3">Shopify</h4>
                                    <p className="mb-5">Empower your online business with our Shopify development prowess. We specialize in creating customized websites for enhanced e-commerce success.</p>
                                    <Link to="/Shopify" className="change_btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fas fa-globe fa-7x mb-4"></i>
                                    <h4 className="mb-3">Hosting & Domain</h4>
                                    <p className="mb-5">Easily establish your online presence with our hassle hosting, domain registration, and setup services, ensuring a smooth and secure launch for your website.</p>
                                    <Link to="/HostDom" className="change_btn">Read More</Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="services-item bg-light">
                            <div className="p-4 text-center services-content">
                                <div className="services-content-icon">
                                    <i className="fas fa-laptop fa-7x mb-4"></i>
                                    <h4 className="mb-3">Graphic Designing</h4>
                                    <p className="mb-5">Enhance your brand's visual identity with our professional graphic design services, crafting eye-catching logos, marketing materials, and more to make your business shine.</p>
                                    <Link to="/GraphicDes" className="change_btn">Read More</Link>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
