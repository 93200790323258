import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

export const Contact = () => {
    const form = useRef();
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_agpu7yb', 'template_11qkotv', form.current, {
                publicKey: 'HoZ52IBatzHVLTIBc',
            })
            .then(
                (result) => {
                    console.log(result.text);
                    setMessage('Thanks for reaching us. We will contact you shortly.');
                },
                (error) => {
                    console.log(error.text);
                    setMessage('Failed to send message. Please try again later.');
                },
            );
            e.target.reset()
    };

    return (
        <div>
            <div className="container-fluid py-5 mb-5">
                <div className="container">
                    <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
                        <h5 className="blue_color">Get In Touch</h5>
                        <h1 className="mb-3">Contact Us</h1>
                        <p className="mb-2">If you have any queries.Please Contact Our team.</p>
                    </div>
                    <div className="contact-detail position-relative p-4">
                        {/* <!-- <div className="row g-5 mb-5 justify-content-center">
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style="width: 64px; height: 64px;">
                                    <i className="fas fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Address</h4>
                                    <a href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6" target="_blank" className="h5">23 rank Str, NY</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style="width: 64px; height: 64px;">
                                    <i className="fa fa-phone text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Call Us</h4>
                                    <a className="h5" href="tel:+0123456789" target="_blank">+012 3456 7890</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                            <div className="d-flex bg-light p-3 rounded">
                                <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style="width: 64px; height: 64px;">
                                    <i className="fa fa-envelope text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h4 className="text-primary">Email Us</h4>
                                    <a className="h5" href="mailto:info@example.com" target="_blank">info@example.com</a>
                                </div>
                            </div>
                        </div>
                    </div> --> */}
                        <div className="row p-lg-4 align-items-center justify-content-center">
                            <div className="col-lg-6 col-12 p-lg-0 p-4 wow fadeIn" data-wow-delay=".3s">
                                {/* <!-- <div className="p-5 h-100 rounded contact-map">
                                <iframe className="rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.4710403339755!2d-73.82241512404069!3d40.685622471397615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26749046ee14f%3A0xea672968476d962c!2s123rd%20St%2C%20Queens%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1686493221834!5m2!1sen!2sbd" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div> --> */}
                                <div>
                                    <div>
                                        <span className="d-flex gap-3"><i className="fa fa-phone pt-1 blue_color"></i><h5>Contact:</h5></span>
                                        <p><a href="tel:+923128789629">+(92) 312-8789629</a></p>
                                    </div>
                                    <div>
                                        <span className="d-flex gap-3"><i className="fas fa-map-marker-alt pt-1 blue_color"></i><h5>Address:</h5></span>
                                        <p className='change_color2'>Karachi,Pakistan</p>
                                    </div>
                                    <div>
                                        <span className="d-flex gap-3"><i className="fa fa-envelope pt-1 blue_color"></i><h5>Email:</h5></span>
                                        <p><a href="mailto:info@fixtechsolution.net">info@fixtechsolution.net</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 pt-lg-0 pt-3 pb-lg-0 pb-3 wow fadeIn" data-wow-delay=".5s">
                                <form className="contact-form" ref={form} onSubmit={sendEmail}>
                                    <div className="mb-4">
                                        <input type="text" className="form-control" name='name' placeholder="Your Name" required />
                                    </div>
                                    <div className="mb-4">
                                        <input type="email" className="form-control" name='email' placeholder="Your Email" required />
                                    </div>
                                    <div className="mb-4">
                                        <input type="tel" className="form-control" name='phone' placeholder="Phone" required />
                                    </div>
                                    <div className="mb-4">
                                        <textarea className="form-control" name='message' rows="6" cols="10" placeholder="Message" required></textarea>
                                    </div>
                                    <div className="text-start">
                                        <button className="change_btn_3" type="submit">Send Message</button>
                                    </div>
                                </form>
                                {message && <p className={`message ${message.includes('reaching') ? 'success' : 'failure'}`}>{message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
