import React from 'react'
import { Link } from 'react-router-dom'

export const GraphicDes = () => {
  return (
    <div>
        <div className="container-fluid py-5 my-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/nikita-kachanovsky-g-YiX8ynmnY-unsplash.jpg" className="img-fluid w-75 rounded" alt="" style={{marginBottom: "20%", marginLeft: "12%"}}/>
                            </div>
                        </div>
                       
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">Graphic Design</h5>
                            <h1 className="mb-4">Unleash Your Brand's Visual Potential with Fixtech's Graphic Design Services</h1>
                            <p>At Fixtech, we're not just about code – we're also masters of visual storytelling. Our graphic design services are tailored to elevate your brand's identity, captivate your audience, and leave a lasting impression. Whether you need a stunning logo, eye-catching graphics for your website, or compelling marketing materials, Fixtech has you covered. Let us bring your brand to life with creativity, precision, and flair – contact us today to get started.</p>
                            <p className="mb-4">At FixTech Solutions, excellence is not just a goal, it's our standard. We are dedicated to delivering solutions that not only meet but exceed industry standards, ensuring reliability, scalability, and performance.</p>
                            <Link to="/contact" className="change_btn_2 mehroz">Book an order</Link>
                            <Link to="/services" className="change_btn_2 fact_color mehroz">See more Services</Link>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
