import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div>
        <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
            <div className="container pt-5 pb-4">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6 text-lg-start text-center">
                        <Link to="/">
                            <h1 className="text-white fw-bold d-block footer-logo">Fix<span className="blue_color">Tech</span></h1>
                            <p className="mt-3 text-light">At FixTech Solutions, we are committed to revolutionizing the digital landscape through cutting-edge IT services, innovative web and app development solutions, and strategic digital marketing strategies.</p>
                        </Link>
                        <div className="d-flex justify-content-lg-start justify-content-center gap-2 hightech-link">
                            <a href="https://www.facebook.com/profile.php?id=61558796034738" className=" nav-fill btn btn-square rounded-circle circle_hover"><i className="fab fa-facebook-f"></i></a>
                            <a href="" className=" nav-fill btn btn-square rounded-circle circle_hover"><i className="fab fa-instagram"></i></a>
                            <a href="mailto:info@fixtechsolution.net" className=" nav-fill btn btn-square rounded-circle circle_hover"><i className="fa fa-envelope"></i></a>
                            <a href="https://www.linkedin.com/in/fixtech-solution-948809304/" className=" nav-fill btn btn-square rounded-circle circle_hover"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="blue_color">Quick Links</h3>
                        <div className="mt-4 d-flex flex-column short-link">
                            <Link to="/aboutaddition" className="mb-2 text-white"><i className="fas fa-angle-right blue_color me-2"></i>About us</Link>
                            <Link to="/services" className="mb-2 text-white"><i className="fas fa-angle-right blue_color me-2"></i>Our Services</Link>
                            <Link to="/project" className="mb-2 text-white"><i className="fas fa-angle-right blue_color me-2"></i>Our Projects</Link>
                            <Link to="/contact" className="mb-2 text-white"><i className="fas fa-angle-right blue_color me-2"></i>Contact us</Link>
                            
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="blue_color">Help Link</h3>
                        <div className="mt-4 d-flex flex-column help-link">
                            <Link to="/TermsAndCondition" className="mb-2 text-white"><i className="fas fa-angle-right blue_color me-2"></i>Terms and Conditions</Link>
                            <Link to="/PrivacyPolicy" className="mb-2 text-white"><i className="fas fa-angle-right blue_color me-2"></i>Privacy Policy</Link>
                            
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="blue_color">Contact Us</h3>
                        <div className=" mt-4 d-flex flex-column contact-link">
                            <p href="#" className="pb-3 mb-0 text-light border-bottom border-primary"><i className="fas fa-map-marker-alt blue_color me-2"></i> Karachi,Pakistan</p>
                            <a href="tel:+923128789629" className="py-3 border-bottom border-primary tel_num"><i className="fas fa-phone-alt blue_color me-2"></i>+(92) 312-8789629</a>
                            <a href="mailto:info@fixtechsolution.net" className="py-3 tel_num" ><i className="fas fa-envelope blue_color me-2"></i>info@fixtechsolution.net</a>
                        </div>
                    </div>
                </div>
                <hr className="text-light mt-5 mb-4"/>
                <div className="row">
                    <div className="text-center">
                        <span className="text-light"><i className="fas fa-copyright me-2"></i>2024 <span className="blue_color">FixTech Solution</span> All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
