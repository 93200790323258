import React from 'react'
import { Link } from 'react-router-dom'

export const AboutAddition = () => {
    return (
        <div>
            <div className="container-fluid py-5 my-5">
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                            <div className="h-100 position-relative">
                                <img src="img/image-10.jpg" className="img-fluid w-75 rounded" alt="" style={{ marginBottom: "25%", marginLeft: "12%" }} />
                                {/* <!-- <div className="position-absolute w-75" style="top: 25%; left: 25%;">
                                <img src="img/image-9.jpg" className="img-fluid w-100 rounded" alt="">
                            </div> --> */}
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn text-lg-start text-center" data-wow-delay=".5s">
                            <h5 className="blue_color">About Us</h5>
                            <h1 className="mb-4">About FixTech Agency And It's Innovative IT Solution</h1>
                            <p>At Fixtech, we're more than just a tech company – we're your partners in digital innovation. With
                                a relentless focus on excellence, we specialize in providing tailored solutions to elevate your
                                online presence. From cutting-edge website design and development to seamless Shopify store
                                setups, we're dedicated to helping you thrive in the digital landscape.
                                Our commitment to excellence extends beyond aesthetics – it's about performance and
                                reliability. With our hosting and domain services, we lay the foundation for success, ensuring
                                lightning-fast loading times, ironclad security, and round-the-clock support.
                                But we're not just about technology – we're about people. Our team of experts is passionate
                                about understanding your unique needs and crafting solutions that exceed your expectations.
                                We believe in transparency, integrity, and genuine partnership, guiding you every step of the
                                way on your digital journey.
                                Whether you're a budding entrepreneur or an established enterprise, Fixtech is here to fuel your
                                growth and unlock your digital potential. Let's embark on this journey together and make your
                                digital dreams a reality."</p>

                            <div className='mt-5'>
                                <h2 className='mb-4 blue_color'>Our Vision:</h2>
                                <p>At FixTech Solution, we envision a future where businesses seamlessly integrate technology to drive innovation and achieve unparalleled success. Our goal is to lead this digital transformation by offering solutions that redefine industry standards and empower enterprises to thrive in the digital age.</p>
                            </div>

                            <div className='mt-5'>
                                <h2 className='blue_color mb-4'>Our Mission:</h2>
                                <p>Our mission is to empower businesses of all sizes to harness the full potential of technology, thereby facilitating their growth and prosperity. We are committed to delivering excellence in every aspect of our work, from crafting customized software solutions to executing targeted marketing campaigns. At FixTech Solution, our mission is to build lasting partnerships and shape the future of technology.</p>
                            </div>

                            <div>
                                <h2 className='mb-4 blue_color mt-5'>Why Choose FixTech Solutions?</h2>
                                <ul>
                                    <li><h6>Excellence Every Time:</h6><p>At FixTech Solution, we hold ourselves to the highest standards of excellence. We follow industry best practices to ensure that our solutions are reliable, scalable, and high-performing.</p></li>

                                    <li><h6>Innovation is Key:</h6><p>Innovation is central to everything we do. We leverage the latest advancements to develop solutions that meet the evolving needs of our clients.</p></li>

                                    <li><h6>Putting Clients First:</h6><p>Your satisfaction is our priority. We work closely with you to understand your goals and challenges, ensuring that our solutions are tailored to your needs.</p></li>

                                    <li><h6>Expertise that Delivers:</h6><p>Our team of professionals combines technical expertise with strategic thinking to deliver solutions that drive tangible results.</p></li>

                                    <li><h6>Support Every Step of the Way:</h6><p>Our commitment to your success doesn't end with project completion. We offer ongoing support and guidance to help you navigate the digital landscape with confidence.</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
